import React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import "./header.css";
import { useForm } from "react-hook-form";
import axios from "axios";
import swal from "sweetalert";
import PopupMenu from "./PopupMenu";

const Header = ({ profileProp }) => {
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const navigate = useNavigate();
    const location = useLocation();
    const [profile, setProfile] = useState(profileProp);
    useEffect(() => {
        setProfile(profileProp);
    }, [profileProp]);
    const [setting, setSetting] = useState(JSON.parse(localStorage.getItem("setting")));
    const [isOpen, setIsOpen] = useState(false);
    const [signUp, setSignUp] = useState(1);
    const [menu, setMenu] = useState(null);
    const openPopup = (num) => {
        setMenu(num);
        setIsOpen(true);
    };
    const openLogin = (num) => {
        profile ? setMenu(num) : setSignUp(1);
        setIsOpen(true);
    };
    const closePopup = () => {
        setIsOpen(false);
    };
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
            .then((res) => {
                if (profile != res.data.data) {
                    setProfile(res.data.data);
                    localStorage.setItem("profile", JSON.stringify(res.data.data));
                }
            })
            .catch((err) => localStorage.removeItem("user"));
        if (localStorage.getItem("setting") !== null && localStorage.getItem("setting") !== "undefined") {
            setSetting(JSON.parse(localStorage.getItem("setting")));
        } else {
            axios.get(`${process.env.REACT_APP_API_URL}/setting/get`, {}).then((res) => {
                setSetting(res.data.data[0]);
            });
        }
        const timer = setInterval(() => {
            setProfile(JSON.parse(localStorage.getItem("profile")));
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, []);
    /*useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/casino/create`);
    }, [profile]);
    useEffect(() => {
        const interval = setInterval(() => {
            axios
                .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
                .then((res) => {
                    if (profile != res.data.data) {
                        setProfile(res.data.data);
                        localStorage.setItem("profile", JSON.stringify(res.data.data));
                    }
                })
                .catch((err) => localStorage.removeItem("user"));
        }, 5000);
        return () => clearInterval(interval);
    }, []);*/
    const data = [
        {
            title: "HOT",
            sub: "Games",
            to: "/sicbo"
        },
        {
            title: "Bắn Cá",
            sub: "Fishing",
            to: "/fishing"
        },
        {
            title: "Casino",
            sub: "Live Casino",
            to: "/casino"
        },
        {
            title: "Game Bài",
            sub: "PVP",
            to: "/pvp"
        },
        {
            title: "Nổ Hũ",
            sub: "Slot",
            to: "/slots"
        },
        {
            title: "Xổ Số",
            sub: "Lottery",
            to: "/lottery"
        },
        {
            title: "Thể Thao",
            sub: "Sports",
            to: "/sports"
        },
        {
            title: "Esports",
            sub: "",
            to: "/esports"
        },
        {
            title: "Đá Gà",
            sub: "",
            to: "/cockfighting"
        }
    ];
    const logOut = () => {
        localStorage.removeItem("user");
        localStorage.removeItem("currentUser");
        localStorage.removeItem("profile");
        localStorage.removeItem("data");
        localStorage.removeItem("data1");
        var now = new Date();
        var time = now.getTime();
        var expireTime = time;
        now.setTime(expireTime);
        document.cookie = "jwt=;Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
        window.location.href = "/";
    };
    const getProfile = () => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
            .then((res) => {
                if (profile != res.data.data) {
                    setProfile(res.data.data);
                    localStorage.setItem("profile", JSON.stringify(res.data.data));
                }
            })
            .catch((err) => localStorage.removeItem("user"));
    };
    return (
        <>
            <header>
                <div className="header">
                    <div className="container">
                        <div className="main_header d-flex">
                            <div className="logo">
                                <Link to="/">
                                    <img alt="" src="/images/home_logo.webp" />
                                </Link>
                            </div>
                            <div className="center-menu">
                                <div className="center-menu_item" onClick={() => navigate("/promotion")}>
                                    <img src="/images/i_gift_j.png.avif" />
                                    <div className="name">KHUYẾN MÃI</div>
                                </div>
                                <div className="center-menu_item" onClick={() => (profile ? openLogin(2) : navigate("/invite"))}>
                                    <img src="/images/i_cooperate_j.png.avif" />
                                    <div className="name">ĐẠI LÝ</div>
                                </div>
                                <div className="center-menu_item" onClick={() => window.open(setting?.down_link, "_blank")}>
                                    <img src="/images/i_download_j.png.avif" />
                                    <div className="name">TẢI APP</div>
                                </div>
                                <div className="center-menu_item" onClick={() => openLogin(2)}>
                                    <img src="/images/i_wallet_j.png.avif" />
                                    <div className="name">NẠP TIỀN</div>
                                </div>
                                <div className="center-menu_item" onClick={() => openLogin(7)}>
                                    <img src="/images/i_transfer_j.png.avif" />
                                    <div className="name">CHUYỂN TIỀN</div>
                                </div>
                                <div className="center-menu_item" onClick={() => openLogin(3)}>
                                    <img src="/images/i_withdraw_j.png.avif" />
                                    <div className="name">RÚT TIỀN</div>
                                </div>
                            </div>
                            <div className="menu_login">
                                {profile ? (
                                    <div className="box-login" style={{ justifyContent: "space-between" }}>
                                        <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                                            <div className="user-has-login" style={{ cursor: "pointer" }} onClick={() => openPopup(1)}>
                                                <img src="/images/i_info_user.png.avif" />
                                                <span>{profile?.username}</span>
                                            </div>
                                            <div className="user-has-login">
                                                <img src="/images/i_amount.png.avif" />
                                                <span style={{ color: "#f09b1b" }}>{Math.floor(profile?.money).toLocaleString("vi-VN")}</span>
                                            </div>
                                        </div>
                                        <div className="user-has-login" style={{ cursor: "pointer" }} onClick={logOut}>
                                            <span>
                                                <img src="/images/i_quit.png.avif" style={{ width: "24px", height: "24px" }} />
                                            </span>
                                            <span>Đăng xuất</span>
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        <div className="box-login">
                                            <button
                                                className="btn-login"
                                                onClick={() => {
                                                    setSignUp(1);
                                                    setIsOpen(true);
                                                }}>
                                                Đăng nhập
                                            </button>
                                            <button
                                                className="btn-register"
                                                onClick={() => {
                                                    setSignUp(2);
                                                    setIsOpen(true);
                                                }}>
                                                Đăng ký
                                            </button>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main_menu" style={{ background: "#fff" }}>
                    <div className="container d-flex">
                        <nav>
                            <ul>
                                {data.map((item, index) => (
                                    <li className={location.pathname === item.to ? "menu_item active" : "menu_item"} key={index}>
                                        {index == 11 || index == 12 ? (
                                            <a href={item.to} target="_blank">
                                                <div className="item_title">{item.title}</div>
                                            </a>
                                        ) : (
                                            <Link to={item.to}>
                                                <div className="item_title">{item.title}</div>
                                            </Link>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        </nav>
                    </div>
                </div>
            </header>
            <PopupMenu profile={profile} isOpen={isOpen} isClose={closePopup} menuActive={menu} getProfile={getProfile} signUp={signUp} />
        </>
    );
};

export default Header;
