import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "./popupmenu.css";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import Account from "../user/account/Account";
import Recharge from "../user/recharge/Recharge";
import Withdraw from "../user/withdraw/WithDraw";
import HistoryRecharge from "../user/history/HistoryRecharge";
import HistoryWithdraw from "../user/history/HistoryWithdraw";
import HistoryCasino from "../user/history/HistoryCasino";
import HistoryBet from "../user/history/HistoryBet";
import HistoryTransfer from "../user/history/HistoryTransfer";
import Transfer from "../user/transfer/Transfer";
import Promo from "../user/account/Promo";
import Login from "./Login";
import Register from "./Register";
import axios from "axios";

const PopupMenu = ({ profile, isOpen, isClose, menuActive, getProfile, signUp }) => {
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const [menuLogin, setMenuLogin] = useState(1);
    useEffect(() => {
        setMenuLogin(signUp);
    }, [signUp]);
    const [activeOption, setActiveOption] = useState(0);
    const handleOptionClick = (option) => {
        setActiveOption(option);
    };
    useEffect(() => {
        setActiveOption(menuActive);
    }, [menuActive]);
    const [bankCard, setBankCard] = useState(false);
    const menuClick = () => {
        setActiveOption(3);
        setBankCard(true);
    };
    const regClick = () => {
        setMenuLogin(2);
    };
    const logClick = () => {
        setMenuLogin(1);
    };
    return (
        <>
            {isOpen === true && profile != null && (
                <div className="backdrop">
                    <div className="menu-popup">
                        <div className="menu-popup-grid">
                            <div className="menu-sidebar">
                                <div className="menu-title">Thông tin tài khoản</div>
                                <div className="menu-sidebar-list">
                                    <ul>
                                        <li onClick={() => handleOptionClick(1)} className={`menu-sidebar-item ${activeOption == 1 ? "active" : ""}`}>
                                            <i className="acmc_icon icon_securityCenter"></i>
                                            <span>Tài khoản</span>
                                        </li>
                                        <li onClick={() => handleOptionClick(7)} className={`menu-sidebar-item ${activeOption == 7 ? "active" : ""}`}>
                                            <i className="acmc_icon icon_rewardCenter"></i>
                                            <span>Chuyển ví</span>
                                        </li>
                                        <li onClick={() => handleOptionClick(9)} className={`menu-sidebar-item ${activeOption == 9 ? "active" : ""}`}>
                                            <i className="acmc_icon icon_rewardCenter"></i>
                                            <span>Lịch sử chuyển ví</span>
                                        </li>
                                        <li onClick={() => handleOptionClick(2)} className={`menu-sidebar-item ${activeOption == 2 ? "active" : ""}`}>
                                            <i className="acmc_icon icon_depositv2"></i>
                                            <span>Nạp tiền</span>
                                        </li>
                                        <li onClick={() => handleOptionClick(3)} className={`menu-sidebar-item ${activeOption == 3 ? "active" : ""}`}>
                                            <i className="acmc_icon icon_withdraw"></i>
                                            <span>Rút tiền</span>
                                        </li>
                                        <li onClick={() => handleOptionClick(4)} className={`menu-sidebar-item ${activeOption == 4 ? "active" : ""}`}>
                                            <i className="acmc_icon icon_personalHistoryCopy"></i>
                                            <span>Lịch sử trò chơi</span>
                                        </li>
                                        <li onClick={() => handleOptionClick(8)} className={`menu-sidebar-item ${activeOption == 8 ? "active" : ""}`}>
                                            <i className="acmc_icon icon_personalHistoryCopy"></i>
                                            <span>Lịch sử sảnh game</span>
                                        </li>
                                        <li onClick={() => handleOptionClick(5)} className={`menu-sidebar-item ${activeOption == 5 ? "active" : ""}`}>
                                            <i className="acmc_icon icon_transactionHistory"></i>
                                            <span>Lịch sử nạp tiền</span>
                                        </li>
                                        <li onClick={() => handleOptionClick(6)} className={`menu-sidebar-item ${activeOption == 6 ? "active" : ""}`}>
                                            <i className="acmc_icon icon_personalProfit"></i>
                                            <span>Lịch sử rút tiền</span>
                                        </li>
                                        <li onClick={() => handleOptionClick(10)} className={`menu-sidebar-item ${activeOption == 10 ? "active" : ""}`}>
                                            <i className="acmc_icon icon_securityCenter"></i>
                                            <span>Mã khuyến mãi</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="menu-content">
                                <div className="menu-content-box">
                                    {activeOption == 1 && <Account profile={profile} menuClick={menuClick} getProfile={getProfile} />}
                                    {activeOption == 2 && <Recharge profile={profile} getProfile={getProfile} />}
                                    {activeOption == 3 && <Withdraw profile={profile} bankCard={bankCard} getProfile={getProfile} />}
                                    {activeOption == 4 && <HistoryBet profile={profile} getProfile={getProfile} />}
                                    {activeOption == 5 && <HistoryRecharge profile={profile} getProfile={getProfile} />}
                                    {activeOption == 6 && <HistoryWithdraw profile={profile} getProfile={getProfile} />}
                                    {activeOption == 7 && <Transfer profile={profile} getProfile={getProfile} />}
                                    {activeOption == 8 && <HistoryCasino profile={profile} getProfile={getProfile} />}
                                    {activeOption == 9 && <HistoryTransfer profile={profile} getProfile={getProfile} />}
                                    {activeOption == 10 && <Promo profile={profile} getProfile={getProfile} />}
                                </div>
                                <div className="close-popup" onClick={isClose}>
                                    <CloseIcon />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {isOpen === true && profile == null && (
                <div className="backdrop">
                    <div className="popup-login">
                        <div className="popup-login-slide">
                            <img src="/images/1717680076846848.png.avif" />
                        </div>
                        <div className="popup-login-form">
                            {menuLogin == 1 && <Login regClick={regClick} getProfile={getProfile} />}
                            {menuLogin == 2 && <Register logClick={logClick} getProfile={getProfile} />}

                            <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", padding: "20px 0 10px", color: "#fff" }}>
                                <Link
                                    to="/"
                                    style={{
                                        textDecoration: "none",
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "8px",
                                        justifyContent: "center",
                                        color: "#4d595e",
                                        fontSize: "15px"
                                    }}>
                                    <img alt="" src={require("../../static/i_quit.png")} style={{ height: "24px" }} />
                                    TRANG CHỦ
                                </Link>
                                <Link
                                    to="/service"
                                    style={{
                                        textDecoration: "none",
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "8px",
                                        justifyContent: "center",
                                        color: "#4d595e",
                                        fontSize: "15px"
                                    }}>
                                    <img alt="" src={require("../../static/i_service.png")} style={{ height: "24px" }} />
                                    CSKH
                                </Link>
                            </div>
                            <div className="close-popup" onClick={isClose}>
                                <CloseIcon />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default PopupMenu;
